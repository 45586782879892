/* FlipCard.css */

/* Basic Styles */
.flip-container {
  perspective: 1000px;
}

.flipper {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
  width: 100%;
  height: 100%;

}

.front,
.back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

/* Front and Back Content Styles */
.front {
  z-index: 2;
}

.back {
  transform: rotateY(180deg);
}

/* Flipped Class for Animation */
.flipped .flipper {
  transform: rotateY(180deg);
}



