.popup {
  color: white;
  border-radius: 5px;
  position: fixed;
  max-width: 80%;
  width: 300px;
  top: 0px;
  left: 0px;
  z-index: 1000;
}

.handle {
  background-color: #000;
  padding: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-align: center;
  font-weight: bold;
}
