@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-modal { padding: 0% !important;}
.ant-modal-content { padding: 0% !important;}
.game-container {
  position: relative;
  /* Add any other styling for the game container */
}

.centered-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* Add any other styling for the centered label container */
}

.centered-label-6 {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* Add any other styling for the centered label container */
}

.centered-label-2 {
  position: absolute;
  top: 10%;
  left: 10%;
  /* transform: translate(-50%, -50%); */
  /* Add any other styling for the centered label container */
}

.label-text {
  /* Add styling for the label text, e.g., font size, color, etc. */
}
