.Info_arc__PoMRn {
    fill: none;
    stroke-linecap: round;
    stroke: #26ae00;
}

.Info_cr__aaXjQ {
    stroke: #d9d9d9;
    fill: var(--theme-color-111);
}

.Info_lbl__4GCFj {
    color: var(--theme-color84);
    font-size: 1.2rem;
    font-weight: 500;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
}


.Info_pr__3p1lI {
    height: 6rem;
    left: 50%;
    position: absolute;
    top: -3rem;
    transform: translateX(-50%);
    width: 6rem;
}

.Info_bl__zaSor {
    border-top: 0.1rem solid var(--theme-color-111);
    left: 50%;
    position: absolute;
    top: -0.1rem;
    transform: translateX(-50%);
    width: 6.5rem;
}

.Info_container__wwIvv {
    align-items: end;
    background: var(--theme-color-111);
    border: 0.1rem solid rgb(172, 172, 172);
    border-radius: 0.5rem;
    display: flex;
    filter: drop-shadow(0 4px 6px rgba(0,0,0,.12));
    height: 6.7rem;
    justify-content: center;
    margin-top: 4.6rem;
    padding: 0 1rem;
    position: relative;
    width: 100%;
}

.Info_c1__7FKr-, .Info_c2__ICbcI {
    align-items: start;
    color: var(--theme-color-113);
    display: flex;
    flex: 1 1;
    flex-direction: column;
    font-size: 1.2rem;
    font-weight: 500;
    gap: 0.5rem;
    height: 100%;
    justify-content: center;
}

.Info_c2__ICbcI {
    align-items: end;
}