.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* latest update */

.main_content{
  height: 100vh;
  overflow-y: scroll;
}

.sliderr{
  display: flex;
}

.inner-sidebar{

}

.dis-none{
  display: none;
}

.centered-label-3 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0; /* Grey background */
}

.loader-container {
  width: 100%;
  background-color: #ccc; /* Lighter grey for loader container */
  height: 20px; /* Adjust the height as needed */
  overflow: hidden;
}

.loader {
  height: 100%;
  background: linear-gradient(to left, #3498db, #2980b9); /* Blue gradient for loader */
  position: relative;
  overflow: hidden;
}

.text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.text-overlay::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 100%;
  background: linear-gradient(to right, transparent 50%, white 50%);
  animation: textGradient 2s linear infinite;
}

@keyframes textGradient {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}
