

.wallet-icon{
display: none;
width: 40px;
height: 40px;
padding: 5px;
border-radius: 50px;
}

.connect-wallet{
    display: block;

}


@media only screen and (max-width: 780px) {
   .wallet-icon{
    display: block;
   }
   .connect-wallet{
    display: none;
   }
  }